<template>
  <div>
    <sub-head v-bind:subTitle="content['설치 사례']"></sub-head>
    <div class="page_padding">
      <div class="paddingTB">
        <div class="text-center header">
          <div>
            <span class="fontBold font41 word_break text333">
              해피테이블
              <span class="fontBold font41 textPink">설치 사례</span></span
            >
          </div>
          <div class="verticalSpacer-half"></div>
          <!-- <div>
            <span class="fontBold font41 textPink"
              >실제 사용자 및 이용자에 대한 피드백</span
            >
          </div> -->
        </div>
        <div class="text333">
          <ul>
            <li
              v-on:click="filter_reference_kind('all')"
              :class="{active: reference_kind == 'all'}"
            >
              <div><span class="fontS">전체</span></div>
            </li>
            <li
              v-on:click="filter_reference_kind('welfare')"
              :class="{active: reference_kind == 'welfare'}"
            >
              <div><span class="fontS word_break">복지관 및 요양원</span></div>
            </li>
            <li
              v-on:click="filter_reference_kind('dementia')"
              :class="{active: reference_kind == 'dementia'}"
            >
              <div><span class="fontS word_break">치매 안심 센터</span></div>
            </li>
            <li
              v-on:click="filter_reference_kind('other')"
              :class="{active: reference_kind == 'other'}"
            >
              <div><span class="fontS word_break">그 외</span></div>
            </li>
          </ul>
        </div>
        <div class="reference">
          <div
            v-for="(item, info) in filtering_reference(reference_kind, is_all)"
            :class="[]"
            :key="info"
            class="d-flex item"
          >
            <div>
              <div class="imgWrap">
                <img :src="getImgSrc(item.img_src)" />
              </div>
              <div class="text-left paddingTB1">
                <div class="headlineWrap">
                  <span class="fontExtraBold font20 text333">{{
                    item.headline
                  }}</span>
                </div>
              </div>
            </div>
          </div>
          <div
            class="item hidden"
            v-for="item in game_count()"
            :key="item"
          ></div>
        </div>
        <button v-show="is_more()" v-on:click="is_show()" class="btn">
          {{ button_value }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import jsonContents from "../../assets/source/json/sub_top.json";
import referContents from "../../assets/source/json/reference_info.json";
import subHead from "../../components/sub_head.vue";
export default {
  components: {subHead},
  data() {
    return {
      content: jsonContents,
      reference_info: referContents.slice().reverse(),
      all_reference_info: referContents.slice().reverse(),
      show: false,
      is_all: "",
      button_value: "더보기",
      reference_kind: "all",
      is_moreshow: true,
      showing_count: 12,
    };
  },
  methods: {
    getImgSrc(src) {
      var images = require.context(
        "../../assets/source/images/",
        false,
        /\.jpg$/
      );
      return images("./" + src + ".jpg");
    },
    is_show() {
      if (this.show == false) {
        // 전체 보기
        this.show = true;
        this.is_all = "all";
        this.button_value = "접기";
      } else {
        // 상위만 보기
        this.show = false;
        this.is_all = "";
        this.button_value = "더보기";
      }
    },
    game_count() {
      var reference_info = Object.keys(this.reference_info).length;
      var empty_box = [];
      if (reference_info % 3 != 0) {
        reference_info = 3 - (reference_info % 3);
      }
      for (var i = 0; i < reference_info; i++) {
        empty_box[i] = "empty" + i;
      }
      return empty_box;
    },

    filtering_reference: function(kind, more) {
      var count = this.showing_count;
      // 필터링 후 더보기 버튼 유무 판단
      this.is_more();

      // 전체일 때
      if (kind == "all") {
        // 더보기 눌렀으면
        if (more == "all") {
          return this.reference_info;
        } else {
          return this.reference_info.filter(function(content, index) {
            return index < count;
          });
        }
      }
      // 설치사례 필터 눌렀을 때
      else {
        // 더보기 눌렀으면
        if (more == "all") {
          return this.reference_info;
        } else {
          return this.reference_info.filter(function(content, index) {
            return content.kind == kind && index < count;
          });
        }
      }
    },
    filter_reference_kind(kind) {
      this.reference_kind = kind;
      this.reference_info = [];
      if (this.reference_kind == "all") {
        this.reference_info = this.all_reference_info;
      } else {
        for (var i = 0; i < this.all_reference_info.length; i++) {
          if (this.all_reference_info[i].kind == this.reference_kind) {
            this.reference_info.push(this.all_reference_info[i]);
          }
        }
      }
      this.show = false;
      this.is_all = "";
      this.button_value = "더보기";
    },
    is_more() {
      var count = this.showing_count;
      if (this.reference_info.length <= count) {
        this.is_moreshow = false;
      } else {
        this.is_moreshow = true;
      }
      return this.is_moreshow;
    },
  },
};
</script>

<style scoped>
ul li {
  list-style: none;
  border-right: 1px solid #e6e6e6;
  width: 25%;
  padding: 1.5rem 0;
  background-color: #f4f4f4;
  color: #333;
  font-size: 1.1rem;
}
ul li:last-child {
  border-right: 0px solid gray;
}
.active {
  background-color: #db3960;
  border-color: #db3960 !important;
  color: white;
}
ul {
  display: flex;
  width: 100%;
  /* border: 1px solid gray; */
}
.reference {
  padding: 3rem 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.reference .item {
  width: 32%;
  justify-content: center;
  padding: 1rem 0;
}
.reference img {
  border-radius: 20px;
}
.imgWrap {
  width: 100%;
}
.imgWrap img {
  width: 100%;
}
.d-flex div {
  width: 100%;
}
.margin-right {
  margin-right: auto;
}
.margin-left {
  margin-left: auto;
}

.btn {
  color: black !important;
  border: none;
  background-color: #e3e3e3;
  outline: none;
  margin: 0 auto;
}
.btn:hover {
  background-color: #e3e3e3;
}
.headlineWrap {
  padding-bottom: 0.2rem;
}
.contentWrap {
  color: #606060;
}
.item.hidden {
  border: none;
  box-shadow: none;
  cursor: unset;
  margin: 0;
  padding: 0;
}
@media (min-width: 768px) and (max-width: 1023px) {
  .reference {
    padding: 1.5rem 0;
  }
  .reference .item {
    width: 48%;
    justify-content: center;
    padding: 1rem 0;
  }
  .item.hidden {
    padding: 0;
  }
}
@media (max-width: 767px) {
  .reference {
    flex-direction: column;
    padding: 1.5rem 0;
  }
  .reference .d-flex {
    width: 100%;
    padding: 0.5rem 0;
  }
}
</style>
